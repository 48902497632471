import React, { lazy, Suspense } from "react";
// import BlankPage from "../pages/blank";
import Home from '../assets/layout/home.png'
import IssuerBoard from '../assets/layout/issuerboard.png'
import Marketplace from '../assets/layout/marketplace.png'
import HomeActive from '../assets/layout/home-active.png'
import IssuerBoardActive from '../assets/layout/issuerboard-active.png'
import MarketplaceActive from '../assets/layout/marketplace-active.png'

import projectListIcon from '../assets/icon/projectList.svg'
import sharesTypeIcon from '../assets/icon/sharesType.svg'
import homeIcon from '../assets/icon/home.svg'
import capTableIcon from '../assets/icon/capTable.svg'

// 懒加载、防闪屏
const lazyLoad = (src) => (
  <Suspense fallback={<>...</>}>{React.createElement(lazy(src))}</Suspense>
);
//路由配置
const routes = [
  {
    path: "/",
    element: lazyLoad(() => import("../layout/index")),
    children: [
      {
        element: lazyLoad(() => import("../pages/issuerHome")),
        name: "Home",
        path: "/",
        role: "issuer",
        icon: homeIcon,
        activeIcon: homeIcon
      },
      {
        path: "/issuer-cre-marketplace",
        element: lazyLoad(() => import("../pages/issuerCreMarketplace")),
        name: "Marketplace",
        role: "issuer",
        icon: projectListIcon,
        activeIcon: projectListIcon
      },
      {
        path: "/new-project/:id",
        element: lazyLoad(() => import("../pages/newProject")),
        name: "New Project",
        role: "issuer",
        hidden: true,
      },
      {
        path: '/notifications',
        element: lazyLoad(() => import('../pages/messagePage')),
        name: 'Notifications',
        hidden: true
      },
      {
        element: lazyLoad(() =>
          import("../pages/issuerHome/completionOfTransaction")
        ),
        name: "Transaction Details",
        path: "/completion-of-transaction",
        role: "issuer",
        icon: sharesTypeIcon,
        activeIcon: sharesTypeIcon
      },
      {
        element: lazyLoad(() =>
          import("../pages/issuerHome/capTable")
        ),
        name: "Cap Table Management",
        path: "/cap-table",
        role: "issuer",
        icon: capTableIcon,
        activeIcon: capTableIcon
      },
      {
        path: "/cre-marketplace/details/:id",
        element: lazyLoad(() => import("../pages/creMarketplaceDetails")),
        name: "Marketplace details",
        hidden: true,
      },
      {
        path: "/cre-marketplace/edit/:id",
        element: lazyLoad(() => import("../pages/creMarketplaceEdit")),
        name: "Marketplace edit",
        hidden: true,
      },
      {
        path: "/cre-marketplace/buy/:id",
        element: lazyLoad(() => import("../pages/buyDeel")),
        name: "Marketplace Buy",
        hidden: true,
      },
     
      {
        path: "/my-deals",
        element: lazyLoad(() => import("../pages/myDeals")),
        name: "My Deals",
        role: "issuer",
        hidden: true,
      },
      {
        path: "/investor-board",
        element: lazyLoad(() => import("../pages/investorBoard")),
        name: "Investor Board",
        role: "issuer",
        hidden: true,
      },
    
      {
        path: "/trading",
        element: lazyLoad(() => import("../pages/trading")),
        name: "Trading",
        role: "issuer",
        hidden: true,
      },
      {
        path: "/wallet",
        element: lazyLoad(() => import("../pages/wallet")),
        name: "Wallet",
        hidden: true,
      },
      {
        path: "/my-portfolio/transaction-deatils",
        element: lazyLoad(() => import("../pages/transactionDeatils")),
        name: "TransactionDeatils",
        hidden: true,
      },
      //  issuer
      {
        element: lazyLoad(() => import("../pages/subscriptionInformation")),
        name: "Subscription Information",
        path: "subscription-information/:id/:status",
        hidden: true,
      },
      {
        element: lazyLoad(() => import("../pages/totalInvestors")),
        name: "Total Investors",
        path: "total-investors",
        hidden: true,
      },
      {
        element: lazyLoad(() => import("../pages/totalInvestorsDetails")),
        name: "totalInvestorsDetails",
        path: "total-investors-details/:id",
        hidden: true,
      },
      {
        element: lazyLoad(() => import("../pages/toBeConfirmedDetails")),
        name: "toBeConfirmedDetails",
        path: "to-be-confirmed-details/:id/:status",
        hidden: true,
      },
      {
        element: lazyLoad(() => import("../pages/completionOfTransactionDetails")),
        name: "toBeConfirmedDetails",
        path: "transaction-details/:id/:status",
        hidden: true,
      },

      {
        path: "/select-project",
        element: lazyLoad(() => import("../pages/selectProject")),
        name: "Select Project",
        hidden: true,
      },
      {
        path: "/issuer/my-profile",
        element: lazyLoad(() => import("../pages/issuerMyProfile")),
        name: "Issuer Profile",
        role: "issuer",
        hidden: true,
      },
      {
        path: '/show-all/:id',
        element: lazyLoad(() => import('../pages/showAllImg')),
        name: 'Show All',
        hidden: true,
        role: "issuer",
      },
    ],
  },
  {
    path: "/login",
    element: lazyLoad(() => import("../pages/auth/login/index")),
  },
  {
    path: "/kyc/:accesstoken",
    element: lazyLoad(() => import("../pages/auth/kyc/index")),
  },
  {
    path: "/forget-password",
    element: lazyLoad(() => import("../pages/auth/forgetPassword/index")),
  },
  {
    path: "/reset-password",
    element: lazyLoad(() => import("../pages/auth/resetPassword/index")),
  },
  {
    path: "/sign-up",
    element: lazyLoad(() => import("../pages/auth/signUp/index")),
  },
  {
    path: "/sign-up-investor",
    element: lazyLoad(() => import("../pages/auth/signUpInvestor/index")),
  },
  {
    path: "/sign-up-success",
    element: lazyLoad(() => import("../pages/auth/signUpSuccess/index")),
  },
];
export default routes;
